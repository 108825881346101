
import QrCode from '~/components/PaymentWaitingPay/QrCode.vue'
export default {
  middleware: ['auth', 'authOrder'],
  components: {
    QrCode
  },
  data () {
    return {
      order: null
    }
  },
  mounted () {
    if (this.order && this.order.payment_status !== 1) {
      this.$router.push(`/my/orders/${this.$route.params.id}`)
    }
    this.$echo.channel('locopack-payment-success')
      .on('payment-success', ({ orderId, userId }) => {
        if (parseInt(orderId) === parseInt(this.$route.params.id)) {
          this.$router.push('/payment-success/' + orderId)
        // this.$router.push('/my/orders/' + orderId)
        }
        console.log('### DEBUG PUSHER >> Received event data:', orderId)
        // ทำสิ่งที่คุณต้องการกับข้อมูลที่ได้รับที่นี่
      })
  },
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      const { data } = await $axios.$get(`orders/${params.id}`)
      return {
        order: data
      }
    } catch (e) {
      console.log('error', e)
      redirect('/')
    }
  }
}
